@use '@angular/material' as mat;
@import
  'style/base/typography',
  'style/abstracts/functions',
  'style/abstracts/variables',
  'style/abstracts/mixins';

.hero {
  width: 100%;
  height: 0;
  padding: 50% 0 0;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: breakpoint('md.min')) {
    padding: 40% 0 0;
  }

  @media (min-width: breakpoint('lg.min')) {
    padding: 33% 0 0;
  }

  .hero-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
  }

  .hero-content {
    width: breakpoint('lg.min');
    max-width: 100%;
    margin: 0 auto;
    padding: 16px 16px;

    @media (min-width: breakpoint('sm.min')) {
      padding: 16px 48px;
    }

    @media (min-width: breakpoint('lg.min')) {
      padding: 32px 64px;
    }

    @media (min-width: breakpoint('xl.min')) {
      width: breakpoint('xl.min');
    }
  }

  .hero-main-content {
    color: #ffffff;
    text-align: center;

    &.with-sidecontent {
      @media (min-width: breakpoint('md.min')) {
        text-align: start;
      }
    }
  }

  h1.headline {
    @media (min-width: breakpoint('sm.min')) {
      margin-bottom: 24px;

      .mat-icon {
        @include mat-icon-size(mat.m2-font-size($wise-typography, 'headline-3'));
      }
    }

    @media (min-width: breakpoint('md.min')) {
      .mat-icon {
        @include mat-icon-size(mat.m2-font-size($wise-typography, 'headline-2'));
      }
    }
  }

  h1.with-tagline {
    margin-bottom: 4px;

    @media (min-width: breakpoint('sm.min')) {
      margin-bottom: 8px;
    }

    @media (min-width: breakpoint('md.min')) {
      margin-bottom: 16px;
    }
  }

  h2.tagline {
    font-weight: 400;
    margin-bottom: 0 !important;

    .mat-icon {
      @include mat-icon-size(mat.m2-font-size($wise-typography, 'headline-5'));
    }

    @media (min-width: breakpoint('sm.min')) {
      text-align: start;
      .mat-icon {
        @include mat-icon-size(mat.m2-font-size($wise-typography, 'headline-4'));
      }
    }
  }

  @media (max-width: breakpoint('xs.max')) {
    h1.headline, h2.tagline {
      line-height: 1.2;
    }
  }
}
