@use '@angular/material' as mat;

$wise-typography: mat.m2-define-typography-config(
  $headline-5: mat.m2-define-typography-level(24px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(17px, 24px, 500),
  $subtitle-2: mat.m2-define-typography-level(17px, 24px, 400),
  $body-1: mat.m2-define-typography-level(16px, 22px, 500),
  $body-2: mat.m2-define-typography-level(16px, 22px, 400),
  $caption: mat.m2-define-typography-level(14px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500, $letter-spacing: 'normal'),
);

textarea {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 100%;
}
